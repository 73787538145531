export const Search = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={15}
    viewBox="0 0 14 15"
    fill="none"
  >
    <g clipPath="url(#clip0_409_579)">
      <path
        d="M10.2007 10.4258L12.8224 13.0475"
        stroke={props.color ?? "white"}
        strokeWidth="0.972267"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6569 6.63899C11.6569 3.7431 9.30932 1.39551 6.41341 1.39551C3.51751 1.39551 1.16992 3.7431 1.16992 6.63899C1.16992 9.53491 3.51751 11.8825 6.41341 11.8825C9.30932 11.8825 11.6569 9.53491 11.6569 6.63899Z"
        stroke={props.color ?? "white"}
        strokeWidth="0.972267"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_409_579">
        <rect
          width="13.9826"
          height="13.9826"
          fill="white"
          transform="translate(0.00488281 0.230225)"
        />
      </clipPath>
    </defs>
  </svg>
)
