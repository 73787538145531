export const CategoryIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="none"
  >
    <path
      d="M10.6665 2.83325C10.6665 2.212 10.6665 1.90137 10.768 1.65634C10.9033 1.32964 11.1629 1.07007 11.4896 0.934745C11.7346 0.833252 12.0452 0.833252 12.6665 0.833252C13.2878 0.833252 13.5984 0.833252 13.8434 0.934745C14.1701 1.07007 14.4297 1.32964 14.565 1.65634C14.6665 1.90137 14.6665 2.212 14.6665 2.83325V5.49992C14.6665 6.12117 14.6665 6.43178 14.565 6.67685C14.4297 7.00352 14.1701 7.26312 13.8434 7.39845C13.5984 7.49992 13.2878 7.49992 12.6665 7.49992C12.0452 7.49992 11.7346 7.49992 11.4896 7.39845C11.1629 7.26312 10.9033 7.00352 10.768 6.67685C10.6665 6.43178 10.6665 6.12117 10.6665 5.49992V2.83325Z"
      stroke="black"
      strokeWidth="1.5"
    />
    <path
      d="M10.6665 12.1667C10.6665 11.5455 10.6665 11.2349 10.768 10.9898C10.9033 10.6631 11.1629 10.4035 11.4896 10.2682C11.7346 10.1667 12.0452 10.1667 12.6665 10.1667C13.2878 10.1667 13.5984 10.1667 13.8434 10.2682C14.1701 10.4035 14.4297 10.6631 14.565 10.9898C14.6665 11.2349 14.6665 11.5455 14.6665 12.1667C14.6665 12.788 14.6665 13.0986 14.565 13.3437C14.4297 13.6703 14.1701 13.9299 13.8434 14.0653C13.5984 14.1667 13.2878 14.1667 12.6665 14.1667C12.0452 14.1667 11.7346 14.1667 11.4896 14.0653C11.1629 13.9299 10.9033 13.6703 10.768 13.3437C10.6665 13.0986 10.6665 12.788 10.6665 12.1667Z"
      stroke="black"
      strokeWidth="1.5"
    />
    <path
      d="M1.3335 10.1667C1.3335 8.9096 1.3335 8.28107 1.72402 7.89053C2.11454 7.5 2.74308 7.5 4.00016 7.5H5.3335C6.59058 7.5 7.2191 7.5 7.60963 7.89053C8.00016 8.28107 8.00016 8.9096 8.00016 10.1667V11.5C8.00016 12.7571 8.00016 13.3856 7.60963 13.7761C7.2191 14.1667 6.59058 14.1667 5.3335 14.1667H4.00016C2.74308 14.1667 2.11454 14.1667 1.72402 13.7761C1.3335 13.3856 1.3335 12.7571 1.3335 11.5V10.1667Z"
      stroke="black"
      strokeWidth="1.5"
    />
    <path
      d="M1.3335 2.83325C1.3335 2.212 1.3335 1.90137 1.43499 1.65634C1.57032 1.32964 1.82988 1.07007 2.15658 0.934745C2.40162 0.833252 2.71224 0.833252 3.3335 0.833252H6.00016C6.62142 0.833252 6.93203 0.833252 7.1771 0.934745C7.50376 1.07007 7.76336 1.32964 7.8987 1.65634C8.00016 1.90137 8.00016 2.212 8.00016 2.83325C8.00016 3.45451 8.00016 3.76513 7.8987 4.01017C7.76336 4.33687 7.50376 4.59643 7.1771 4.73176C6.93203 4.83325 6.62142 4.83325 6.00016 4.83325H3.3335C2.71224 4.83325 2.40162 4.83325 2.15658 4.73176C1.82988 4.59643 1.57032 4.33687 1.43499 4.01017C1.3335 3.76513 1.3335 3.45451 1.3335 2.83325Z"
      stroke="black"
      strokeWidth="1.5"
    />
  </svg>
)
