"use client"
import { DeviceWithQty } from "@/server/api/cartActions"
import { Cart } from "./icons/cart"
import { useCart } from "@/queries/useCart"

function CartCount() {
  const { data: cart } = useCart()

  const totalQty: number | undefined = cart?.items?.reduce(
    (acc: number, item: DeviceWithQty) => acc + item?.quantity,
    0
  )

  // if (isPending) return <></>

  // if (error)
  //   return (
  //     <p className="text-red-500">
  //       Error {error.name} {error.message}{" "}
  //     </p>
  //   )
  return (
    <>
      <div className="size-4 absolute -top-0.5 -right-1 rounded-full  font-gilroyMedium text-white flex justify-center items-center text-[10px]">
        {totalQty! > 0 ? (
          <span className="relative flex justify-center items-center">
            <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-[#893DE7] opacity-75"></span>
            <span className="relative inline-flex size-4 rounded-full bg-[#893DE7] justify-center items-center">
              <span className="mt-0.5">{totalQty}</span>
            </span>
          </span>
        ) : (
          <></>
        )}
      </div>

      <Cart className="size-6" />
    </>
  )
}

export default CartCount
