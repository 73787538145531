export const HamBurger = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={26}
    viewBox="0 0 26 26"
    fill="none"
  >
    <path
      d="M4.26074 5.99438H17.0527"
      stroke="black"
      strokeWidth="3.198"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.26074 13.4565H21.3167"
      stroke="black"
      strokeWidth="3.198"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.26074 20.9187H12.7887"
      stroke="black"
      strokeWidth="3.198"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
