import { LucideProps } from "lucide-react"

export const NavIcons = {
  profile_icon: (props: LucideProps) => (
    <svg
    {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="101"
      height="101"
      viewBox="0 0 101 101"
      fill="none"
    >
      <path
        d="M75.5083 75.8613C89.3596 62.01 89.3596 39.5525 75.5083 25.7012C61.657 11.8498 39.1995 11.8498 25.3481 25.7012C11.4968 39.5525 11.4968 62.01 25.3481 75.8613C39.1995 89.7127 61.6569 89.7127 75.5083 75.8613Z"
        fill="#F7D8BA"
      />
      <mask
        id="mask0_1743_5250"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="14"
        y="15"
        width="72"
        height="72"
      >
        <path
          d="M75.5083 75.8613C89.3596 62.01 89.3596 39.5525 75.5083 25.7012C61.657 11.8498 39.1995 11.8498 25.3481 25.7012C11.4968 39.5525 11.4968 62.01 25.3481 75.8613C39.1995 89.7127 61.6569 89.7127 75.5083 75.8613Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_1743_5250)">
        <path
          d="M85.2097 88.133H16.7749C19.9303 73.968 28.3992 71.1387 28.3992 71.1387L44.7222 63.5042L50.9076 63.4478H51.077L57.2687 63.5042L73.5917 71.1387C73.5917 71.1387 82.0543 73.968 85.2097 88.133Z"
          fill="white"
        />
        <path
          d="M54.731 22.8134C54.731 22.8134 60.4264 20.2753 62.4458 25.0743C64.3892 29.6946 56.67 34.0971 56.67 34.0971L54.731 22.8134Z"
          fill="#1E0B02"
        />
        <path
          d="M44.0784 41.9681C44.1386 44.1016 42.458 45.8807 40.3245 45.9409C38.191 46.0012 36.4119 44.3206 36.3517 42.187C36.2914 40.0535 37.9721 38.2744 40.1056 38.2142C42.2391 38.154 44.0176 39.8346 44.0784 41.9681Z"
          fill="#C67D69"
        />
        <path
          d="M38.0205 41.5346C38.0205 41.5346 39.5575 40.7862 41.0223 43.4561"
          stroke="#A8523F"
          strokeWidth="0.469364"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M62.0544 45.9422C64.1889 45.9422 65.9193 44.2118 65.9193 42.0772C65.9193 39.9427 64.1889 38.2123 62.0544 38.2123C59.9198 38.2123 58.1895 39.9427 58.1895 42.0772C58.1895 44.2118 59.9198 45.9422 62.0544 45.9422Z"
          fill="#C67D69"
        />
        <path
          d="M64.233 41.4726C64.233 41.4726 62.6754 40.7681 61.2871 43.4781"
          stroke="#A8523F"
          strokeWidth="0.469364"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M56.0932 50.9834C56.0932 50.9834 53.9139 52.521 51.9284 52.9005C49.943 53.2801 48.0121 52.1277 47.9876 52.1132C47.9631 52.0994 45.669 50.7795 45.669 50.7795L44.7192 63.5011L44.9764 63.7131C48.3433 66.4903 53.1969 66.5235 56.6014 63.7921L57.0995 63.3925L56.0932 50.9834Z"
          fill="#C67D69"
        />
        <path
          d="M44.7192 49.4421C44.7192 49.4421 46.8848 56.5308 51.8682 56.4524C55.9647 56.3878 56.6252 49.4421 56.6252 49.4421H44.7192Z"
          fill="#1E0B02"
        />
        <path
          d="M61.5718 35.3492C61.5938 39.7179 60.9891 43.4912 60.3793 46.1699C59.7827 48.7928 58.0758 50.6321 55.6574 52.1182L55.217 52.3886C52.6588 53.9607 49.3434 54.014 46.73 52.5247C44.0946 51.0229 42.2446 49.0343 41.6637 46.2257C40.6079 41.1181 40.4668 35.4558 40.4668 35.4558C40.4668 35.4558 38.9832 23.6383 50.3653 23.7951C61.7488 23.952 61.5718 35.3492 61.5718 35.3492Z"
          fill="#C67D69"
        />
        <path
          d="M46.1089 39.7362C46.6175 39.7362 47.0298 39.3239 47.0298 38.8153C47.0298 38.3067 46.6175 37.8943 46.1089 37.8943C45.6003 37.8943 45.188 38.3067 45.188 38.8153C45.188 39.3239 45.6003 39.7362 46.1089 39.7362Z"
          fill="#1E0B02"
        />
        <path
          d="M55.1465 39.7362C55.6551 39.7362 56.0674 39.3239 56.0674 38.8153C56.0674 38.3067 55.6551 37.8943 55.1465 37.8943C54.6379 37.8943 54.2256 38.3067 54.2256 38.8153C54.2256 39.3239 54.6379 39.7362 55.1465 39.7362Z"
          fill="#1E0B02"
        />
        <path
          d="M49.7925 40.1602V42.7008H51.4336"
          stroke="#A8523F"
          strokeWidth="0.469364"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M42.6777 34.9227L48.6963 34.38C48.6963 34.38 48.1574 32.8205 45.724 33.0733C43.2342 33.3324 42.6777 34.9227 42.6777 34.9227Z"
          fill="black"
        />
        <path
          d="M58.1893 34.9227L52.1714 34.3801C52.1714 34.3801 52.7103 32.8205 55.1437 33.0733C57.6329 33.3324 58.1893 34.9227 58.1893 34.9227Z"
          fill="black"
        />
        <path
          d="M47.7324 44.8049H53.6694C53.6694 44.8049 53.3903 46.6235 50.6131 46.6235C47.8353 46.6235 47.7324 44.8049 47.7324 44.8049Z"
          fill="white"
        />
        <path
          d="M40.8542 41.0485C40.8542 41.0485 42.5392 28.2674 41.7149 26.1615C41.7149 26.1615 35.9824 30.9034 40.8542 41.0485Z"
          fill="#1E0B02"
        />
        <path
          d="M61.1415 40.9457C61.1415 40.9457 59.2451 28.7405 59.7708 25.2287C59.7708 25.2287 65.3069 30.1319 61.1415 40.9457Z"
          fill="#1E0B02"
        />
        <path
          d="M59.3747 24.3585L54.1264 23.565L53.6641 22.6572L50.8945 23.0763L48.1248 22.6572L47.6625 23.565L42.4149 24.3585L41.7148 27.1664L41.8874 30.4034C41.8874 30.4034 42.7179 26.7317 45.6099 27.4632C46.2918 27.6357 47.1079 27.9211 48.0627 28.1137C48.9542 28.3082 49.8042 28.396 50.6053 28.4098C50.7 28.4123 50.7985 28.4098 50.8945 28.4104C50.9911 28.4098 51.089 28.4123 51.1837 28.4098C51.9854 28.396 52.8348 28.3082 53.7262 28.1137C54.6804 27.9211 55.5147 27.694 56.1791 27.4632C58.8596 26.5303 59.847 30.2359 59.847 30.2359L60.0741 27.1664L59.3747 24.3585Z"
          fill="#1E0B02"
        />
        <path
          d="M59.27 27.5529C59.27 27.5529 55.8568 30.5985 51.4837 28.2586C51.4837 28.2586 40.3587 35.1121 37.2183 27.6514C34.6005 21.4314 45.4676 12.9908 56.6798 22.5023L59.27 27.5529Z"
          fill="#1E0B02"
        />
        <path
          d="M44.7194 63.5017L41.0728 65.1773C41.0728 65.1773 41.0332 71.2912 50.9425 70.9737C61.1032 70.6488 61.043 65.2695 61.043 65.2695L57.099 63.3925L44.7194 63.5017Z"
          fill="#C67D69"
        />
      </g>
    </svg>
  ),
}
