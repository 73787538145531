import { AxiosError } from "axios"
import { callAPIWithToken } from "../helper"

export type Order = {
  _id?: string
  estimatedDeliveryDate: string
  orderId: string
  deliveryDate?: string
  deliveryStatus?: string
  createdAt: string
  orderTotal: string
  items: {
    image: string[]
    deviceName: string
    price: string
    storage?: string
    ram: string | any[]
    processor: string
    color: string | null
    qty: string
  }[]
}

type SoldInventoryResponse = {
  soldInventory: Order[]
}

export async function fetchOrders(
  type: "all-orders" | "in-transit" | "delivered" | "cancelled"
) {
  let apiURL = "https://gcp-api.edify.club/edifyweb-backend/v1/soldInventory/user"

  switch (type) {
    case "in-transit":
      apiURL += "?status=inTransit"
      break
    case "delivered":
      apiURL += "?status=delivered"
      break
    case "cancelled":
      apiURL += "?status=cancelled"
      break
  }

  try {
    const res = await callAPIWithToken<SoldInventoryResponse>(apiURL, "GET")
    return res?.data // Ensure correct data is returned
  } catch (error) {
    const axiosError = error as AxiosError
    throw {
      status: axiosError.response?.status || 500,
      message: axiosError.response?.data || "Something went wrong",
    }
  }
}

export const getOrderByID = async function ({
  orderId,
}: {
  orderId: string
}): Promise<any> {
  try {
    const res = await callAPIWithToken<any>(
      `https://gcp-api.edify.club/edifyweb-backend/v1/cart/${orderId}`,
      "GET"
    )
    return res?.data
  } catch (e) {
    throw new Error((e as AxiosError)?.message)
  }
}
