"use client"
import { useState } from "react"
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet"
import { Button } from "../../ui/button"
import {
  BookOpen,
  BriefcaseBusiness,
  Handshake,
  LaptopMinimal,
  User,
  ShoppingBasket,
} from "lucide-react"
import { signOut, useSession } from "next-auth/react"
import { useRouter } from "next/navigation"
import { useQuery } from "@tanstack/react-query"
import { fetchOrders } from "@/server/api/orderActions"
import { NavIcons } from "@/app/(root)/_components/nav-icons"
import { useAuthModal } from "@/hooks/use-auth-modal"
import { HamBurger } from "../icons/hamburger"

export const MobileNav = () => {
  const sess = useSession()
  const router = useRouter()
  const { openLoginModal } = useAuthModal()
  const [open, setOpen] = useState(false)

  const { data } = useQuery({
    queryKey: ["my-orders", "all-orders"],
    staleTime: 1000 * 60 * 5,
    enabled: sess.status === "authenticated",
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    queryFn: () => fetchOrders("all-orders"),
  })

  const handleNavigate = (path: string) => {
    router.push(path)
    setOpen(false)
  }

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger asChild>
        <div className="block lg:hidden" aria-label="Open Menu">
          <HamBurger />
        </div>
      </SheetTrigger>
      <SheetContent side="left">
        <SheetHeader className="sr-only">
          <SheetTitle>Menu</SheetTitle>
        </SheetHeader>
        <div className="w-full h-full relative font-gilroyMedium">
          <div className="flex items-center">
            {sess?.status === "authenticated" ? (
              <NavIcons.profile_icon className="-ml-3" />
            ) : (
              <img
                src="/login-icon.svg"
                alt="Login"
                className="size-24 -ml-3"
              />
            )}
            {sess?.status === "authenticated" ? (
              <div className="flex flex-col">
                <p className="text-[#0F0F0F] font-gilroySemiBold text-lg">
                  {sess?.data?.user?.user?.name ||
                    sess?.data?.user?.user?.phone}
                </p>
                <p className="text-base text-[#6C7072] font-gilroyMedium">
                  {data?.soldInventory?.length} Orders
                </p>
              </div>
            ) : (
              <p className="text-[#0F0F0F] font-gilroySemiBold text-lg">
                Login/Signup
              </p>
            )}
          </div>

          <div className="h-[1px] my-5 bg-[#E2E4E5] px-2"></div>

          <ul className="space-y-6 p-2">
            <li
              onClick={() => handleNavigate("/b2b")}
              className="flex items-center gap-3 cursor-pointer"
            >
              <BriefcaseBusiness /> Business
            </li>
            <li
              onClick={() => handleNavigate("/about-us")}
              className="flex items-center gap-3 cursor-pointer"
            >
              <Handshake /> About us
            </li>
            <li
              onClick={() => window.open("https://deviceflow.ai/", "_blank")}
              className="flex items-center gap-3 cursor-pointer"
            >
              <LaptopMinimal /> Startups
            </li>
            <li
              onClick={() => window.open("https://edify.club/blog/", "_blank")}
              className="flex items-center gap-3 cursor-pointer"
            >
              <BookOpen /> Blogs
            </li>
            <li
              onClick={() => handleNavigate("/cart")}
              className="flex items-center gap-3 cursor-pointer"
            >
              <ShoppingBasket /> Cart
            </li>
          </ul>

          <div className="h-[1px] my-5 bg-[#E2E4E5] px-2"></div>

          <ul className="space-y-6 p-2">
            <li
              onClick={() => handleNavigate("/profile")}
              className="flex items-center gap-3 cursor-pointer"
            >
              <ShoppingBasket /> Orders
            </li>
            <li
              onClick={() => handleNavigate("/profile")}
              className="flex items-center gap-3 cursor-pointer"
            >
              <User /> Profile
            </li>
          </ul>

          {sess?.status === "authenticated" ? (
            <Button
              onClick={() => signOut({ redirect: true, redirectTo: "/" })}
              className="w-full absolute bottom-0 left-0"
            >
              Log Out
            </Button>
          ) : (
            <Button
              onClick={() => {
                openLoginModal()
                setOpen(false)
              }}
              className="w-full flex gap-4 absolute bottom-0 left-0"
            >
              Log In
            </Button>
          )}
        </div>
      </SheetContent>
    </Sheet>
  )
}
