"use client"

import * as React from "react"
import * as DialogPrimitive from "@radix-ui/react-dialog"

import { cn } from "@/lib/utils"
import { Search } from "../icons/search"
import { DialogTitle } from "@/components/ui/dialog"
import { Input } from "@/components/ui/input"
import { usePathname, useRouter, useSearchParams } from "next/navigation"
import { X } from "lucide-react"

export const MobileSearch = () => {
  const router = useRouter()
  const searchParams = useSearchParams()
  const pathname = usePathname()

  const [term, setTerm] = React.useState(searchParams.get("q") || "") // Initialize with query param if available
  const [isOpen, setIsOpen] = React.useState(false)

  // Update URL when `term` changes
  React.useEffect(() => {
    const query = term ? `?q=${encodeURIComponent(term)}` : ""
    if (!pathname.startsWith("/all-products") && query !== "") {
      router.replace(`/all-products${query}`)
    } else if (pathname.startsWith("/all-products")) {
      router.replace(`/all-products${query}`)
    }
  }, [term])

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault()
      setIsOpen(false)
    }
  }
  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <button
          type="button"
          className="text-sm size-9 bg-black rounded-full flex items-center justify-center"
          onClick={() => setIsOpen(true)}
        >
          <Search />
        </button>
      </DialogTrigger>
      <DialogContent className="w-[90%] rounded-lg p-0">
        <DialogTitle className="sr-only">Search anything</DialogTitle>
        <div className="flex h-full items-center w-full px-3 rounded-md border">
          <Search color="#BEBEBE" />
          <Input
            id="search"
            type="text"
            autoFocus
            value={term}
            onChange={(e) => setTerm(e.target.value)}
            onKeyDown={handleKeyDown}
            onKeyPress={handleKeyDown}
            placeholder="Search anything"
            className="w-full text-sm font-gilroyRegular border-0 placeholder:text-[#C5C5C5] focus:ring-0 focus-visible:ring-0 focus-visible:ring-offset-0"
          />

          <X
            className="cursor-pointer"
            color="#BEBEBE"
            size={18}
            onClick={() => {
              setTerm("")
              if (pathname !== "/") {
                router.replace(`/all-products`)
              }
              setIsOpen(false)
            }}
          />
        </div>
      </DialogContent>
    </Dialog>
  )
}

const Dialog = DialogPrimitive.Root

const DialogTrigger = DialogPrimitive.Trigger

const DialogPortal = DialogPrimitive.Portal

const DialogOverlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Overlay
    ref={ref}
    className={cn(
      "fixed inset-0 z-50 bg-background/80 backdrop-blur-sm  data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
      className
    )}
    {...props}
  />
))
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName

const DialogContent = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <DialogPortal>
    <DialogOverlay />
    <DialogPrimitive.Content
      ref={ref}
      className={cn(
        "fixed left-[50%] top-[15%] z-50 grid w-full max-w-lg translate-x-[-50%] translate-y-[-50%] gap-4 border bg-background p-6 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg",
        className
      )}
      {...props}
    >
      {children}
    </DialogPrimitive.Content>
  </DialogPortal>
))
DialogContent.displayName = DialogPrimitive.Content.displayName
